/** @jsxImportSource @emotion/react */
import { View } from "@react-pdf/renderer";
import { Badge } from "reactstrap";
import Field, { DataSource } from "./Field";


/*
    This field renders a badge for each domain of a stage from a layer.
*/
class LayerStageColorBadgesField extends Field {
    field_type = "layer_stage_color_badges_field";

    backend_table = "layer_meta";

    badges: string[] = [];

    badge_objects: { text: string, key: string, status_color: string }[] = [];

    constructor(key: string, label: string, badges: string[], options = {}) {
        super(key, label, options);
        this.badges = badges;
    }


    // * set the value from one of the the objects
    set_value(datasource: DataSource): this {
        this.badge_objects = this.badges.map((i) => ({ key: i, text: i, status_color: datasource.layer[i] || "secondary" }));
        this.value = this.badge_objects.map((i) => `${i.text}: ${i.status_color}`).join(", ");
        return this;
    }

    display_component(): JSX.Element {
        // !warning: please do not remove the space between the badge tags, it is needed to render the badges correctly
        return <span>
            {this.badge_objects.map((i) => <Badge style={{ padding: "10px" }} key={i.key} size="sm" title={i.text} className="me-1" color={i.status_color}> </Badge >)}
        </span>;
    }

    display_vector(): JSX.Element {
        return <View>
            {this.badge_objects.map((i) => <View style={{ padding: "10px", backgroundColor: "black" }} key={i.key}> </View >)}
        </View>;
    }
}

export default LayerStageColorBadgesField;


