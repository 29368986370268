/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { Badge } from "reactstrap";
import { Check, CHECK_CARD_TYPE, CHECK_LOCATION, Layer, LAYER_STAGE, LAYER_TYPE } from "../../../../actions/Layers/constants";
import useConfig from "../../../../actions/Tenants/config/configHook";
import { path_summary } from "../../../../actions/Tenants/config/constants";
import mq from "../../../../constants/mediaqueries";
import { getFilledArrayOrDefault } from "../../../../utils";
import { FromLink } from "../../../../utils/useNavigateFrom";
import { Loading } from "../../../Helper/Loading";

type CheckCardProps = {
    check: Check;
    layer: Layer;
    card_type: any;
    lastStep: string;
}
const CheckCard = ({ check, layer, card_type, lastStep }: CheckCardProps) => {
    const getFlagModifier = (check) => {
        // * get status from internal data processing
        if (check.status) {
            return `flag-${check.status}`;
        }

        return "";

    };

    const getInnerCard = (check) => {
        if (card_type === CHECK_CARD_TYPE.LABEL) {
            return <div className="display-6 fw-bold" css={css`font-size: 22px;`}>{layer.label}</div>;
        }
        if (card_type === CHECK_CARD_TYPE.SIZE_CATEGORY_OR_LABEL) {
            if (check.size_category) {
                return <div className="display-6 fw-bold" css={css`font-size: 22px;`}>{check.size_category}</div>;
            }
            return <div className="display-6 fw-bold" css={css`font-size: 22px;`}>{layer.label}</div>;
        } if (card_type === CHECK_CARD_TYPE.SIZE_CATEGORY) {
            return <div className="display-6 fw-bold" css={css`font-size: 22px;`}>{check.size_category}</div>;
        }
        return <div>
            <div className="display-6 fw-bold" css={css`font-size: 22px;`}> {dayjs(check.created).format("D")}</div>
            <div className="text-muted fw-bold">{dayjs(check.created).format("MMM")}</div>
        </div>;
    };

    return (
        <FromLink to={`/layer/${check.layer_id}/add-check/${check.test_id}/${lastStep}`} css={css`color: unset;`}>
            <div
                className={`card-alert --${getFlagModifier(check)} text-center py-2 px-3 clickable me-0 mb-0`}
                css={css`min-width: 4.3rem; border-top-width: 6px; border-radius: 8px; border-left-width: 0px; border-right-width: 0px; border-bottom-width: 0px;`}
                title={`Check ${check.test_id}`}
            >
                {getInnerCard(check)}
            </div>
        </FromLink>
    );
};


function filterOnlyLatestCheckPerChildPerLocation(checks: any, card_type: any) {
    // DATE is default card type, in that sense we want to show all checks
    if (!card_type || card_type === CHECK_CARD_TYPE.DATE) {
        return checks;
    }

    const getCompositeKey = (check) => {
        // do not be confused by label, it is label of the card, not layer label
        const { layer_id, label, location, size_category } = check;
        switch (card_type) {
        case CHECK_CARD_TYPE.SIZE_CATEGORY_OR_LABEL:
            return `${layer_id}_${size_category || label}_${location}`;
        case CHECK_CARD_TYPE.SIZE_CATEGORY:
            return `${layer_id}_${size_category}_${location}`;
        case CHECK_CARD_TYPE.LABEL:
            return `${layer_id}_${label}_${location}`;
        default:
            return `${layer_id}_${location}`;
        }
    };
    // Create a Map to store the latest checks for each location and child
    const latestChecksMap = new Map();

    // Iterate through the data and update the latest checks based on configuration
    checks.forEach((check) => {
        const { location_config } = check;
        if (location_config?.only_show_latest_check_on_child) {
            const compositeKey = getCompositeKey(check);
            if (!latestChecksMap.has(compositeKey) || latestChecksMap.get(compositeKey).test_id < check.test_id) {
                latestChecksMap.set(compositeKey, check);
            }
        }
    });

    // Use filter to exclude previous checks from the original array
    return checks.filter((check) => {
        const { location_config } = check;
        if (!location_config) return true;

        // This only applies for specific locations
        if (!location_config?.only_show_latest_check_on_child) {
            return true;
        }
        const compositeKey = getCompositeKey(check);
        return latestChecksMap.has(compositeKey) && latestChecksMap.get(compositeKey).test_id === check.test_id;
    });
}

export default function LayerChecks({ subconfig }) {
    const config = useConfig();
    const checks = useSelector((state:any) => state.layers.checks);
    const layer = useSelector((state:any) => state.layers.current);
    const isLoading = useSelector((state:any) => state.layers.isLoading);
    const checksLoading = useSelector((state:any) => state.layers.checksLoading);

    if (isLoading || !checks || checksLoading) {
        return <Loading />;
    }

    const { filter_field, filter_location } = subconfig;
    // put ALl as last, and rename to other
    let stages = [
        ...config.stages,
        { text: "Other", value: "other" }
    ];

    const addData = (check) => {
        const child = getChild(check);

        // Make sure there is a fruit_type set on the layer (inherit from parent)
        const layer_for_get_location = child ? { ...child, fruit_type: child.fruit_type || layer.fruit_type } : layer;
        const location_config = config.get_location(layer_for_get_location, check);

        const card = <CheckCard
            layer={child || layer}
            check={check}
            card_type={subconfig.card_type}
            lastStep={getFilledArrayOrDefault(location_config?.flow, [path_summary]).at(-1)} />;

        return {
            ...check,
            location: getStage(check, location_config),
            location_config,
            layer: child || layer,
            card,
            is_manual: check.is_manual === true // lets be more strict about what is true or false here (null should be false to)
        };
    };

    // * Only shows tabs we consider to be stages... and other
    const preDefinedStages = stages.filter((stage) => Object.values(LAYER_STAGE).includes(stage.value) || stage.value === "other");

    const getStage = (check, location_config) => {
        // Either the value of the location is a stage
        if (preDefinedStages.some((stage) => stage.value === check.location)) {
            return check.location;
        }

        // Or the allocate_stage prop is set
        if (location_config && preDefinedStages.some((stage) => stage.value === location_config.allocate_stage)) {
            return location_config.allocate_stage;
        }
        return "other";
    };

    const getChild = (test) => layer.children.find((i) => i.id === test.layer_id);

    // label every location that is unconfigured as other
    let theChecks = checks
        .filter((i) => (filter_field && layer.id !== i.layer_id ? i[filter_field] === layer[filter_field] : true))
        .filter((i) => (filter_location && layer.id !== i.layer_id ? i.location === filter_location : true))
        .map(addData)
        .filter((i) => (i.layer.type !== LAYER_TYPE.PURCHASE_ORDER && i.location !== CHECK_LOCATION.LAB_CHECK))
        .sort((a, b) => a.test_id - b.test_id);

    // If we do not show a date on the card... we want to only show the latest label e.g. advance ripening
    theChecks = filterOnlyLatestCheckPerChildPerLocation(theChecks, subconfig.card_type);

    // Order all checks
    stages = preDefinedStages.map((stage) => ({
        ...stage,
        checks: theChecks.filter((check) => check.location === stage.value)
    }));

    return (
        <div className="w-100 d-flex flex-column" css={css`gap: 2rem;`}>
            <CheckWrapper title={<h5 className="mb-0 fw-bold">Stage</h5>}>
                <h5 className="mb-0 fw-bold">Checks</h5>
            </CheckWrapper>

            {stages
                .filter((stage) => !(stage.value === "other" && stage.checks.length === 0))
                .map((stage) => (
                    <CheckWrapper
                        key={stage.value}
                        title={<Badge color="light" className="text-black">{stage.text}</Badge>}
                    >
                        {stage.checks.length > 0 ? (
                            <div className="d-flex" css={css`gap: 1rem; flex-wrap: wrap;`}>
                                {stage.checks.map((check, index) => (
                                    <div css={css`width: fit-content;`} key={index}>
                                        {check.card}
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <span>-</span>
                        )}
                    </CheckWrapper>
                ))}
        </div>
    );
}


function CheckWrapper({ title, children }) {
    return (
        <div className="d-flex flex-wrap justify-content-start">
            <div className="pb-2 pb-lg-0" css={[mq({ width: ["100%", "100%", "100%", "25%"] }), css`max-width: 10rem`]}>{title}</div>
            <div css={[mq({ width: ["100%", "100%", "100%", "75%"] }), css`gap: 1rem;`]} className="d-flex flex-column">{children}</div>
        </div>
    );
}

