/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { faBracketsCurly, faCaretDown, faCaretUp, faCopy, faMinus, faPencil, faTrash } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import {
    Alert,
    Button
} from "reactstrap";
import useConfig from "../../../../actions/Tenants/config/configHook";
import { getFilledArrayOrDefault } from "../../../../utils";
import { toast } from "../../../../utils/toast";
import MetaForm from "../../../Forms/MetaForm";
import DebugModal from "../../../Helper/DebugModal";
import { DisplayFilterForm, default_display_config } from "./DisplayFilterForm";
import { addItemInArray, deleteItemInArray, moveItemDownInArray, moveItemUpInArray, updateItemInArray } from "./util";


type FilterableField = {
    display_filter: object;
    field: object;
    error?: boolean;
};

type FilterableConfigListPropsType = {
    name: string;
    title: string;
    description: string | JSX.Element;
    getFieldTitle: (field: any, display_filter: any) => string | JSX.Element;
    getFieldForm: (field: any, updateFieldItemInArray: undefined | ((attr: string, value: any) => null)) => any[]; // Assuming any[] is the return type
    fields: FilterableField[] | object[];
    setArray: (arr: object[]) => null;
    defaultField: object;
    useDisplayFilter?: false | string[];
    error?: false | string;
}
export default function FilterableConfigList({
    name,
    title,
    description,
    getFieldTitle,
    getFieldForm,
    fields,
    setArray,
    error = false,
    defaultField,
    useDisplayFilter = ["fruit_types", "exclude_fruit_types", "layer_types", "manual_or_with_mini"]
}: FilterableConfigListPropsType) {
    const [display, setDisplay] = useState("preview");
    const [debugModal, setDebugModal] = useState(false);

    // * if no display filter is used, the array contains only the field in the list, else it is {{display_filter: {}, field: {}}
    // * if display_filter is used, make sure the items are in {{display_filter: {}, field: {}} format
    fields = (useDisplayFilter ? getFilledArrayOrDefault(fields, []).map((i) => (i.display_filter ? i : { display_filter: default_display_config, field: i })) : getFilledArrayOrDefault(fields, []).map((i) => ({ field: i, display_filter: false }))) as FilterableField[];

    const _setArray = (newList) => {
        if (useDisplayFilter) {
            setArray(newList);
        } else {
            setArray(newList.map((i: any) => i?.field));
        }
    };

    const swapFields = (fromIndex, toIndex) => {
        if (fromIndex === toIndex) return;

        const updatedFields = [...fields];
        const [movedItem] = updatedFields.splice(fromIndex, 1); // Remove the item from the array
        updatedFields.splice(toIndex, 0, movedItem); // Insert the item at the new position

        _setArray(updatedFields); // Update the state with the reordered array
    };

    const handleImportFromClipboard = (data) => {
        toast.success("Data imported from clipboard");
        _setArray(data);
    };

    return (<div className={`border p-3 mb-3 ${error && display === "preview" && "alert alert-danger"}`} css={css`${error && display === "preview" ? "" : "background:#fcfcfc"};`}>

        <div className="d-flex align-items-center">
            <div className="mb-0">
                <div className="mb-0 fw-bold">{title} [{name}] </div>
                <div className="text-muted">{description}</div>
            </div>
            <div className="mb-0 ms-auto text-muted">{fields && `${fields.length} items`}</div>
            { display === "preview" && <div className="ms-2"><Button size="sm" onClick={() => setDisplay("edit")}>Edit</Button></div>}
            { display === "edit" && <div className="ms-2"><Button size="sm" onClick={() => setDebugModal(true)}><FontAwesomeIcon icon={faBracketsCurly}/></Button></div>}
            { display === "edit" && <div className="ms-2"><Button size="sm" onClick={() => addItemInArray(_setArray, fields, defaultField)}>Add Field</Button></div>}
            { display === "edit" && <div className="ms-2"><Button size="sm" onClick={() => setDisplay("preview")}><FontAwesomeIcon icon={faMinus}/></Button></div>}
        </div>
        <DebugModal close={() => setDebugModal(false)} isOpen={debugModal} docProps={{ data: fields }} enableClipboardImport={true} onChange={handleImportFromClipboard} title="JSON Editor" />
        { display === "edit" && <div>
            {error && <div className="my-3">
                <Alert color="danger">{error}</Alert>
            </div>}
            { fields.map((field, index) => (
                <div key={ index} className="pb-2">
                    <DraggableField index={index} swapFields={swapFields} itemType={name} >
                        <FilterableConfigField
                            item={field}
                            useDisplayFilter={useDisplayFilter}
                            title={getFieldTitle(field?.field, field?.display_filter)}
                            form={getFieldForm(field?.field, ((attr: string, value: any) => updateItemInArray(_setArray, fields, index, attr, value, "field")))}
                            setValue={(attr, value) => updateItemInArray(_setArray, fields, index, attr, value)}
                            deleteItem={() => deleteItemInArray(_setArray, fields, index)}
                            copyItem={() => addItemInArray(_setArray, fields, field)}
                            moveUp={() => {
                                if (index === 0) return;
                                moveItemUpInArray(_setArray, fields, index);
                            }}

                            moveDown={() => {
                                if (index === fields.length - 1) return;
                                moveItemDownInArray(_setArray, fields, index);
                            }}

                        />
                    </DraggableField>
                </div>
            ))}
        </div>
        }
    </div>
    );
}

// * Use name of the form input as itemType, so that you can only drag n drop within the same list
function DraggableField({ index, swapFields, children, itemType }) {
    const [, ref] = useDrop({
        accept: itemType,
        hover(item:any) {
            if (item.index !== index) {
                swapFields(item.index, index);
                item.index = index;
            }
        }
    });

    const [, drag] = useDrag({
        type: itemType,
        item: { index },
    });

    return (
        <div ref={(node) => drag(ref(node))} className="pb-2">
            {children}
        </div>
    );
}


function FilterableConfigField({ item, form, title, setValue, moveUp, moveDown, deleteItem, copyItem, defaultDisplay = "preview", useDisplayFilter }) {
    const [display, setDisplay] = useState(defaultDisplay);
    const [debugModal, setDebugModal] = useState(false);
    const config = useConfig();
    const { display_filter, field } = item;

    const updateDisplayFilter = (field_name, value) => setValue("display_filter", { ...display_filter, [field_name]: value });
    const updateField = (field_name, value) => setValue("field", { ...field, [field_name]: value });


    const hasAdvancedAttributes = form.filter((i) => i?.advanced).length > 0;
    const filteredForm = form.filter((i) => {
        if (i?.advanced && display !== "advanced") {
            return false;
        }
        return true;
    });

    const closeEditAndMoveUp = () => {
        setDisplay("preview");
        moveUp();
    };

    const closeEditAndMoveDown = () => {
        setDisplay("preview");
        moveDown();
    };

    const handleImportFromClipboard = (data) => {
        toast.success("Data imported from clipboard");
        setValue(data);
    };

    return (
        <div className={`p-3 mt-3 border ${item.error || field.error ? "alert alert-danger" : "bg-white"}`}>
            <div className="d-flex justify-content-start align-items-center">
                <div className="me-auto mb-1">{title}</div>
                <div className="me-1 my-1"><Button size="sm" color="light" onClick={() => setDebugModal(true)}><FontAwesomeIcon icon={faBracketsCurly}/></Button></div>
                {display === "preview" && <div className="me-1 my-1"><Button size="sm" color="light" onClick={() => setDisplay("edit")} ><FontAwesomeIcon icon={faPencil}/></Button></div>}
                {display !== "preview" && <div className="me-1 my-1"><Button size="sm" color="light" onClick={() => setDisplay("preview")} ><FontAwesomeIcon icon={faMinus}/></Button></div>}
                <div className="me-1 my-1"><Button size="sm" color="danger" outline onClick={() => deleteItem()} ><FontAwesomeIcon icon={faTrash}/> </Button></div>
                <div className="me-1 my-1"><Button size="sm" color="light" onClick={() => copyItem()} ><FontAwesomeIcon icon={faCopy} /> </Button></div>
                <div className="me-1 my-1"><Button size="sm" disabled={moveUp === false} color="light" onClick={() => closeEditAndMoveUp()}><FontAwesomeIcon icon={faCaretUp}/> </Button></div>
                <div className="me-1 my-1"><Button size="sm" disabled={moveDown === false} color="light" onClick={() => closeEditAndMoveDown()}><FontAwesomeIcon icon={faCaretDown}/> </Button></div>
            </div>
            <DebugModal close={() => setDebugModal(false)} isOpen={debugModal} docProps={{ data: item }} enableClipboardImport={true} onChange={handleImportFromClipboard} title="JSON Editor" />
            {display !== "preview"
            && <div>
                <MetaForm
                    meta={filteredForm}
                    setValue={updateField}
                    object={field}
                    config={config}
                />
                <div className="d-flex justify-content-end">
                    {display === "advanced" && <div className="me-1 mb-1"><Button size="sm" color="light" onClick={() => setDisplay("edit")} >Collapse</Button></div>}
                    {display !== "advanced" && hasAdvancedAttributes && <div className="me-1 mb-1"><Button size="sm" color="light" onClick={() => setDisplay("advanced")} >Advanced</Button></div>}
                </div>
                {display_filter && <DisplayFilterForm object={display_filter} setValue={updateDisplayFilter} attributes={useDisplayFilter} />}
            </div>}
        </div>
    );
}
