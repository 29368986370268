import { useCallback } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import useNavigateFrom from "../../../utils/useNavigateFrom";

export default function useCheckFlowNavigate() {
    const { getNextRoute, getPrevRoute, navigateTo, navigateBack } = useNavigateFrom();
    const location = useLocation();
    const check = useSelector((state: any) => state.checks.current);

    const baseUrl = `/layer/${check.layer_id}/add-check/${check.test_id}`;
    const from = new URLSearchParams(location.search).get("from") || `/layer/${check.layer_id}`;

    const navigateToCheckAction = useCallback((action = "", persistent_from = true, params: any = {}) => {
        navigateTo(`${baseUrl}/${action}`, persistent_from, params);
    }, [navigateTo, baseUrl]);

    const navigateToAnotherCheck = useCallback(
        (test_id, action = "", persistent_from = true, params: any = {}) => {
            navigateTo(`/layer/${check.layer_id}/add-check/${test_id}/${action}`, persistent_from, params);
        },
        [navigateTo, check.layer_id]
    );

    const exitCheckFlow = useCallback(() => navigateBack(from), [navigateBack, from]);

    return { navigateToCheckAction, navigateToAnotherCheck, exitCheckFlow, getNextRoute, getPrevRoute };
}
